<template>
   <div class="content-wrapper">
      <section class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1>{{ $t('signature') }}</h1>
               </div>
            </div>
         </div><!-- /.container-fluid -->
      </section>

      <div v-if="loading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

      <section v-else class="content">
         <div class="container-fluid">
            <div class="row">
               <div class="col-12">
                  <div v-if="signature"  class="callout callout-info">
                     <!-- title row -->
                     <div class="row">
                        <div class="col-12" style="display: flex; flex-direction: column; align-items: center">
                           <h4>{{ $t('my_signature') }}</h4>
                           <p>{{ $t('check_your_signature_details') }}</p>
                        </div>
                        <!-- /.col -->
                     </div>

                     <!-- Table row -->
                     <div class="row mt-4">
                        <div class="col-12 table-responsive">
                           <table class="table table-striped">
                              <tbody>
                                 <tr>
                                    <td>Status:</td>
                                    <td>{{ $t(`${paymentStatus}`) }}</td>
                                 </tr>
                                 <tr>
                                    <td>Plano:</td>
                                    <td>Premium</td>
                                 </tr>
                                 <tr>
                                    <td>Início:</td>
                                    <td>{{ signature.signedDateFormated }}</td>
                                 </tr>
                                 <tr>
                                    <td>Valor:</td>
                                    <td>R$ {{ signature.price }}</td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                        <!-- /.col -->
                     </div>
                     <!-- /.row -->
                  </div>

                  <!-- Main content -->
                  <div style="border-radius: 5px" class="invoice p-3 mb-3">
                     <div v-if="signature">
                        <!-- title row -->
                        <div class="row">
                           <div class="col-12" style="display: flex; flex-direction: column; align-items: center">
                              <h4>{{ $t('my_payments') }}</h4>
                              <p>{{ $t('check_your_payment_details') }}</p>
                           </div>
                           <!-- /.col -->
                        </div>

                        <div v-if="signature.transactions.length === 0" class="row d-flex justify-content-center">
                           <p class="pt-2 text-sm font-italic">{{ $t('you_still_have_no_payment_history') }}</p>
                        </div>

                        <!-- Table row -->
                        <div v-else class="row">
                           <div class="col-12 table-responsive">
                              <table class="table table-striped">
                                 <thead>
                                 <tr>
                                    <th>Data</th>
                                    <th>Status</th>
                                 </tr>
                                 </thead>
                                 <tbody>
                                 <tr v-for="(transcation, idx) in lastFiveSignatureTransactions" :key="idx">
                                    <td>{{ transcation.dateFormated }}</td>
                                    <td>{{ $t(`${transcation.status}`) }}</td>
                                 </tr>
                                 </tbody>
                              </table>
                           </div>
                           <!-- /.col -->
                        </div>
                        <!-- /.row -->
                     </div>

                     <div v-if="signature === null"  class="row mt-5">
                        <!-- /.col -->
                        <div class="col-md-5 col-12 mr-md-5">
                           <p class="lead">Total</p>

                           <div class="table-responsive">
                              <table class="table">
                                 <tr>
                                    <th style="width:50%">Subtotal:</th>
                                    <td>R$ {{ subTotal }}</td>
                                 </tr>
                                 <tr v-if="plan.percent > 0">
                                    <th>Desconto ({{ discountPercent }}%)</th>
                                    <td>R$ {{ discountValue }}</td>
                                 </tr>
                                 <tr>
                                    <th>Total:</th>
                                    <td class="text-bold">R$ {{ total }}</td>
                                 </tr>
                              </table>
                           </div>
                        </div>
                        <!-- /.col -->

                        <!-- /.col -->
                        <div class="col-md-5 col-12 ml-md-5 ml-2">
                           <div>
                              <p class="lead">Pagamento</p>
                              <div class="payment">
                                 <div>
                                    <div class="row">
                                       <div class="col-12">

                                          <div class="form-group mb-4">
                                             <label>Cupon</label>
                                             <div class="row justify-content-start mx-1 my-2">
                                                <input
                                                   v-model="couponName"
                                                   style="border: 1px solid #9a9a97"
                                                   type="text"
                                                   class="form-control-sm"
                                                   placeholder="Cupon de desconto">
                                                <button
                                                   @click.prevent="validateCoupon"
                                                   class="btn btn-success mx-2 btn-sm text-bold">
                                                      Aplicar
                                                </button>
                                             </div>

                                             <span
                                                v-if="isValidCoupon"
                                                style="font-size: 11px; font-style: italic"
                                                class="text-success">
                                                   <i class="pt-1 fas fa-check"></i> {{ $t('discount_successfully_applied') }}
                                             </span>
                                             <span
                                                v-if="isValidCoupon === false"
                                                style="font-size: 11px; font-style: italic"
                                                class="text-danger">
                                                   <i class="pt-1 fas fa-times"></i> {{ $t('coupon_not_found') }}
                                             </span>
                                          </div>

                                       </div>
                                       <div class="col-12">
                                          <hr>
                                          <p class="fw-lighter fs-4">Total: <span
                                             class="text-primary text-bold">R$ {{ total }}</span></p>

                                          <div id="paypal-button-container"></div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <!-- /.col -->
                     </div>
                     <!-- /.row -->
                  </div>
                  <!-- /.invoice -->
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </section>
   </div>
</template>

<script>
import { loadScript } from "@paypal/paypal-js";
import { useToast } from "vue-toastification";
import planService from "../../../services/plan-service";
import couponService from "../../../services/coupon-service";
import signatureService from "../services/signature-service";
import { mapState } from "vuex";

export default {
   name: "Signature",
   setup() {
      const toast = useToast()
      return { toast }
   },
   data () {
      return {
         paypal: null,
         couponName: null,
         isValidCoupon: null,
         originalPrice: 0,
         plans: [],
         plan: {
            uuid: null,
            price: 0,
            percent: 0
         },
         signature: null,
         coupons: [],
         coupon: null,
         loading: false
      }
   },
   computed: {
      ...mapState({
         userId: state => state.auth.user.data.userId
      }),
      lastFiveSignatureTransactions () {
         const transactions = this.filterAndSortTransactions();
         return transactions.slice(0, 5);
      },
      paymentStatus () {
         const transactions = this.filterAndSortTransactions();
         const lastTransaction = transactions[0];
         let transactionDate = new Date(lastTransaction.date);

         transactionDate.setDate(transactionDate.getDate() + 31);
         const today = new Date();

         if (transactionDate > today)
            return 'paid';
         else
            return 'overdue';
      },
      discountPercent () {
         return parseInt(this.plan.percent)
      },
      discountValue () {
         const value = (this.originalPrice * ( this.plan.percent / 100));
         return value.toFixed(2)
      },
      subTotal () {
         return this.originalPrice.toFixed(2)
      },
      total () {
         return this.plan.price.toFixed(2)
      }
   },
   async beforeMount() {
      this.loading = true;
      window.vm = this;

      this.coupons = await couponService.getAllValid();

      const { data: response } = await planService.index();
      this.plans = response.data;
      this.plan = this.plans.find(item => item.default === true);
      this.originalPrice = this.plan.price;

      const { data: sig } = await signatureService.get(this.userId);
      this.signature = sig === '' ? null : sig;

      this.loading = false;

      this.paypal = await loadScript(
         { clientId: "ATuHK8bFNOLj5B-EdeI6SHIo7uvLWVfv4rXvgsI1j4Ln-XKqDbjx3ChaHF9eiGlGucBusduq2GnzQwgO",
            vault: true, intent: 'subscription', currency: "BRL" }
      );

      if (this.paypal) {
         try {
            await this.paypal.Buttons(
               {
                  createSubscription(data, actions) {
                     return actions.subscription.create({
                        'plan_id': 'P-96K79102KS264381UMXF63MQ'
                     });
                  },

                  async onApprove(paymentResponse) {
                     const dataObj = {
                        'uuid': window.vm.userId,
                        'userId': window.vm.userId,
                        'price': window.vm.plan.price,
                        'planId': window.vm.plan.uuid,
                        'lastSignatureId': paymentResponse.subscriptionID,
                        'transactionId': null,
                        'couponUuid': window.vm.coupon !== null ? window.vm.coupon.uuid : null,
                        'couponUsageQuantity': window.vm.coupon !== null ? window.vm.coupon.usageQuantity : null
                     };

                     await signatureService.create(dataObj);
                     const { data: sig } = await signatureService.get(window.vm.userId);
                     window.vm.signature = sig;
                     window.vm.toast.success(window.vm.$t('paid_with_success'));
                   },

                  style: {
                     layout: 'horizontal',
                     color: 'gold',
                     shape: 'pill',
                     label: 'paypal',
                     height: 32,
                     tagline: false
                  }
               }
            ).render("#paypal-button-container");
         } catch (error) {
            console.error("failed to render the PayPal Buttons", error);
         }
      }
   },
   methods: {
      filterAndSortTransactions () {
         let transactions = [];
         //ensure which are the last five completed transactions
         this.signature.transactions.forEach((item) => {
            if (item.status === 'completed')
               transactions.push(item);
         });

         // Sort the array based on date (most recent first)
         transactions.sort((a, b) => {
            // Convert the date strings in date objets for comparison
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);

            // compare the dates
            return dateB - dateA; // Sort descending
         });

         return transactions;
      },
      validateCoupon () {
         this.coupon = this.coupons.find(item => item.name === this.couponName.toUpperCase());

         if (this.coupon) {
            this.plan = this.plans.find(item => item.uuid === this.coupon.planUuid);
            this.isValidCoupon = true;
         }
         else {
            this.isValidCoupon = false;
         }
      }
   }
}
</script>

<style scoped>

</style>
