import axiosClient from '@/axios'

const routeAuthenticated = '/auth/signatures';
const route = '/signatures';

const index = async () => {
   return await axiosClient.get(`${routeAuthenticated}/index`);
}

const get = async uuid => {
   return await axiosClient.get(`${routeAuthenticated}/get/${uuid}`);
}

const create = async signature => {
   return await axiosClient.post(`${routeAuthenticated}/create`, signature);
}

const createUnauthenticated = async signature => {
   return await axiosClient.post(`${route}/create-unauthenticated`, signature);
}

export default {
   index,
   create,
   createUnauthenticated,
   get
}
